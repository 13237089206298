import React from "react";
import { getAuthStore } from "../auth/AuthStore";
import '../App.css';
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { getAdmApi } from "../backend/AdmApi";
import { errMessage } from "../common/tsmodel/Converters";
import { getLogger } from "../common/util/pmlogger";
import { getHttpLayer } from "../common/backend/HttpLayer";
import { HttpLayerImpl } from "../common/backend/HttpLayerImpl";

interface ADState {
  checking: boolean;
}

export class AuthDialog extends React.Component<{}, ADState> {

  private apiRef: React.RefObject<HTMLInputElement>;
  private usernameRef: React.RefObject<HTMLInputElement>;
  private passwordRef: React.RefObject<HTMLInputElement>;

  constructor(props: {}) {
    super(props);
    this.apiRef = React.createRef();
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = { checking: false };
  }

  private onDisconnect = () => {
    getAuthStore().disconnected();
    this.forceUpdate();
  }

  private onSubmit = () => {
    const apiUrl = this.apiRef.current?.value;
    const username = this.usernameRef.current?.value;
    const password = this.passwordRef.current?.value;
    if ((!apiUrl) || (!username) || (!password)) {
      return;
    }
    (getHttpLayer() as HttpLayerImpl).setHttpApiUrl(apiUrl);
    this.setState({ checking: true });
    getAdmApi().admCheck(username, password).then(resp => {
      if (resp.ret === 0) {
        // Check ok
        getAuthStore().connected(username, password);
      } else {
        getAuthStore().failed(username, password);
      }
      this.setState({ checking: false });
    }).catch(errCode => {
      getLogger().info("Auth Failed: %s", errMessage(errCode));
      getAuthStore().failed(username, password);
      this.setState({ checking: false });
    });
  }

  public render() {
    if (this.state.checking) {
      return <div className='AuthDialog'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>&nbsp;Loading...
        </Spinner>
      </div>
    }
    const as = getAuthStore().getAuthState();
    if (as.authOk) {
      return <div className='AuthDisconnect'>
        <a href={(getHttpLayer() as HttpLayerImpl).getHttpApiUrl() + "/screatetest"} target='_blank' rel='noreferrer'>Create Tests</a>&nbsp;&nbsp;&nbsp;
        <button onClick={this.onDisconnect}>disconnect {as.username}</button>
      </div>;
    }
    return <div className='AuthContainer'>
      <Form onSubmit={this.onSubmit} className='AuthDialog'>
        <Form.Row><Col>
          <Form.Group className="mb-3" controlId="formApi">
            <Form.Label>API Url</Form.Label>
            <Form.Control type='text' placeholder="Enter API URL" ref={this.apiRef} defaultValue={(getHttpLayer() as HttpLayerImpl).getHttpApiUrl()} />
          </Form.Group>
        </Col></Form.Row>
        <Form.Row><Col>
          <Form.Group className="mb-3" controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control type='text' placeholder="Enter username" ref={this.usernameRef} defaultValue={as.username} />
          </Form.Group>
        </Col><Col>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' placeholder="Enter password" ref={this.passwordRef} defaultValue={as.password} />
            </Form.Group>
          </Col>
        </Form.Row>
        {as.authFailed ?
          <Form.Text className='text-danger'>Invalid username/password</Form.Text>
          : null}
        <Button variant='primary' type='submit'>
          connect
        </Button>
      </Form>
    </div>;
  }

}
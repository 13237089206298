/* eslint-disable max-len */
export const XLations = {
  fr: {
    translation: {
      test: {
        testval: "true"
      },
      global: {
        // eslint-disable-next-line
        notAlphanumRegexp: "[^A-Za-z0-9 àéèêëîïôöùç\-]",
        errcodes: {
          unknown: "Erreur de communication",
          timeout: "Pas de réponse du serveur",
          unauthorized: "Droits insuffisants",
          malformed: "Requête malformée",
          info: "Avertissement du serveur",
          'client-error': "Erreur client",
          'svr-error': "Erreur serveur",
          OK: "ok",
          UNKNOWN: "Erreur",
          MISSING_FIELD: "Tous les champs ne sont pas remplis",
          /** Create or Update scrutin. CloseDate is too far (4 month from now). */
          CLOSEDATE_TOO_FAR: "La date de fin doit être dans moins de 4 mois",
          /** Db Failure or error response. */
          DBFAIL: "Erreur interne (stockage)",
          /** Invalid data provided to the API. */
          ERR_INVALID_INPUT: "Erreur interne (format)",
          /** MAC or SID allocation error. */
          CANT_ALLOCATE_ID: "Erreur interne (allocation)",
          /** Mac and/or ScrutinId does not match a known Scrutin. */
          SCRUTIN_NOT_FOUND: "Le scrutin demandé n'existe pas",
          /** Server-side error: Scrutin found in several States. */
          ERR_INVALID_STATES: "Erreur interne (états)",
          /** Attempt to Update or GoReady a Scrutin in the READY state. */
          CANT_MODIFY_READY: "Un scrutin Validé ne peut plus être modifié",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the ACTIVE state.  */
          CANT_MODIFY_ACTIVE: "Ce scrutin est en cours - il ne peut plus être modifié.",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the FINISHED state.  */
          CANT_MODIFY_FINISHED: "Ce scrutin est en terminé - il ne peut plus être modifié.",
          /** Attempt to Update a Scrutin with a CloseDate less than 15-min in the Future. */
          CLOSEDATE_TOO_CLOSE: "La date de fin doit être dans plus de 15 minutes",
          /** Attempt to BackToTesting a Scrutin to be opened in less than 5 minutes.  */
          OPENDATE_TOO_CLOSE: "Ce scrutin va commencer - il ne peut plus être modifié.",
          /** Attempt to cast a TestVote on a Scrutin not TESTING, or SendSecret if neither TESTING nor ACTIVE */
          BAD_STATE_FOR_VOTE: "Le scrutin n'est pas actuellement ouvert",
          /** The VoterId does not match any Voter on this Scrutin.  */
          UNKNOWN_VOTERID: "Numéro d'électeur inconnu",
          /** The Voter Secret does not match the Secret of this Voter, identified by ID. */
          BAD_VOTER_SECRET: "Code secret erroné",
          /** The Email does not match any of the emails associated with this Voter, identified by ID & Secret. */
          BAD_VOTER_MAIL: "Le numéro d'électeur et l'email ne correspondent pas",
          /** In a TestVote or RealVote, the supplied Candidate is not associated with a Candidate. */
          UNKNOWN_CANDIDATE: "Candidat inconnu",
          /** In a TestVote or RealVote, the supplied Voter has already expressed a vote. */
          ALREADY_VOTED: "Vote déjà enregistré pour cet électeur",
          /** In a TestVote or RealVote, more Candidates than nVotes. */
          TOO_MANY_CHOICES: "Trop de candidats sélectionnés",
          /** Attempt to express a RealVote before the scrutin OpenDate. */
          BEFORE_OPENTIME: "Le scrutin n'est pas encore ouvert, recommencez ultérieurement",
          /** Attempt to express a RealVote after the scrutin CloseDate. */
          AFTER_CLOSETIME: "Le scrutin est maintenant fermé, il n'est plus possible de voter",
          /** For a VoterStatus or SendSecret request, the Email does not match any Voter. */
          UNKNOWN_VOTER_MAIL: "Aucun électeur avec cet email",
          /** For an Admin command, wrong login and/or password. */
          BAD_AUTHENTICATION: "Identification erronée",
          /** The User identified by login/password has an insufficient AuthLevel (VIEWER vs ADMIN). */
          INSUFFICIENT_PERMISSION: "Permissions insuffisantes",
          default: ""
        },
        error: "Erreur",
        opfailed: "Echec de ",
        err_empty: "Champ requis",
        err_invalid_mail: "Adresse mail incomplète ou non reconnue",
        messagestatus: {
          /** Could not execute the Query, or determine the error, or temp condition. */
          UNKNOWN: "",
          /** Mail Sent, no error returned. */
          SENT: "message envoyé",
          /** Mail ready, not yet sent (queued) */
          QUEUED: "message en cours d'envoi",
          /** Too many messages sent to this Target in a period of time. Retry later. */
          MAX_MESSAGES_TO_TARGET: "trop de messages envoyés récemment à cette adresse - essayez plus tard",
          /** Error in SendMail operation (could not send the email). */
          SENDING_ERROR: "échec de l'envoi du message - retentez plus tard",
          /** 
           * Problem with the Recipient: USER_UNKNOWN, MAILBOX_INACTIVE, QUOTA_EXCEEDED, INVALID_DOMAIN, NO_MAIL_HOST, 
           * RELAY_ACCESS_DENIED, DOMAIN_TYPOFIX, RECIPIENT_BLACKLISTED. QueryStatus operation.
           */
          RECIPIENT_ISSUE: "le message n'est pas arrivé (adresse erronée, boite pleine, ...)",
          /**
           * Mail was labelled as Spam:
           * SPAM_SENDER_BLOCKED(7),SPAM_CONTENT_BLOCKED(8),SPAM_POLICY_ISSUE(9),DOMAIN_GREYLISTED(13),MAILJET_PREBLOCKED(14),MAILJET_SPAM_PREBLOCKED(16),
           * RECIPIENT_SPAM_REPORTER(21). QueryStatus operation.
           */
          LABELLED_SPAM: "le message a été marqué comme spam et bloqué",
          /** Problem with mail content or provider error. */
          OTHER_ERROR: "message non transmis - retentez ultérieurement"
        }
      },
      indexjs: {
        'i18n-inited': "i18n resources loaded."
      },
      basics: {
        LinkCollapsiblePanel: {
          defaultLink: "plus d'infos"
        },
        DayHourRangeSelector: {
          'title-single': "Date",
          'title-range': "Plage de dates",
          'du': "Du",
          'au': "Au",
          'hours': "heures",
          'de': "De",
          'a': "A",
          'inclus': "inclus",
          'err-unset-from': "Sélectionnez une date",
          'err-unset-to': "Sélectionnez une date de fin",
          'err-from-to-order': "La date de fin doit être postérieure au début",
          'err-from-belowmin': "La date de début doit être postérieure au: {{- min}}",
          'err-to-abovemax': "La date de fin doit être antérieure au: {{- max}}",
        },
        DatesManager: {
          maintenant: "maintenant"
        }
      },
      header: {
        lang_fr: "français",
        lang_en: "english"
      },
      wrapper: {
        loading: "chargement en cours",
        'err-timeout': "pas de réponse",
        'err-unauthorized': "droits insuffisants",
        'err-server': "erreur serveur",
        'err-other': "erreur de chargement"
      },
      unsync: {
        loading: "Chargement en cours",
        error: "Erreur",
        go_back: "Retour à l'accueil"
      },
      scrutin: {
        badparam: "Les valeurs sont manquantes ou incorrectes",
        mismatch: "Incohérence entre paramètres",
        title: "Vovotes - Créer un Scrutin",
        organame: "Organisé par",
        organame_sub: "Votre nom ou celui de votre organisation",
        organame_ph: "Association xxx",
        orgamail: "Votre email",
        orgamail_sub: "Vous y recevrez le lien pour déclencher et administrer le scrutin",
        orgamail_ph: "",
        orgagroup: "Organisation",
        orgagroup_sub: "Nom court (30 car.) utilisé dans les titres d'emails",
        orgagroup_ph: "Association amicale",
        displayname: "Titre du scrutin",
        displayname_sub: "Décrivez l'objet du scrutin en 80 caractères",
        displayname_ph: "Election du conseil d'administration",
        datetitle: "Ouverture du scrutin",
        datetitle_sub: "",
        datetitle_hours: "heures",
        du: "du",
        au: "au",
        cancel: "Annuler",
        save: "Enregistrer",
        delete: "Tout supprimer",
        delete_panel_head: "Supprimer le scrutin",
        delete_panel_text: "Etes-vous sûr de vouloir supprimer ce scrutin et toutes les informations que vous avez entrées ?",
        delete_panel_confirm: "Oui, tout supprimer",
        delete_panel_cancel: "Annuler",
        'save-opname': "L'enregistrement",
      },
      voters: {
        textlines: "Liste des électeurs",
        textlines_sub: "1 ligne / électeur: une ou plusieurs adresses email, et éventuellement le nom de l'électeur",
        textlines_ph: "John Doe john@doe.com johndoe@gmail.com\njack@outlook.com\nJean <jean@protonmail.com>",
        help_title_how: "Comment entrer la liste des électeurs",
        help_how_1: "Vous pouvez ici composer ou coller la liste des électeurs: un par ligne.",
        help_how_2: "Chaque électeur doit avoir au moins une adresse email; vous pouvez lui associer plusieurs adresses mail: il aura toujours droit à un seul vote.",
        help_how_3: "Les mots autres sont interprêtés comme le nom de l'électeur, et les caractères spéciaux sont ignorés.",
        help_how_4: "Par exemple: 'Monsieur john@doe.com John Doe' est interprêté comme:",
        help_how_5: "Adresses email: 'john@doe.com'",
        help_how_6: "Nom: 'Monsieur John Doe'",
        help_how_7: "La ligne: 'Monsieur John Doe <john@doe.com>' donnera le même résultat.",
        help_title_use: "Usage des adresses email",
        help_use_1: "Les adresses emails sont utilisées EXCLUSIVEMENT pour valider la participation à ce scrutin.",
        help_use_2: "Nous n'enverrons aucun mail promotionnel ou autre à ces participants.",
        help_use_3: "Nous ne partageons pas ces adresses.",
        help_use_4: "Même dans le cadre de ce scrutin, aucun email ne sera envoyé sans autorisation, le processus est le suivant",
        help_use_5: "Vous communiquez aux électeurs un lien pour voter (le même pour tous).",
        help_use_6: "L'électeur entre son email, et le système vérifie qu'il est enregistré'.",
        help_use_7: "Seulement à ce moment, un email lui est envoyé pour communiquer le code confidentiel pour voter.",
        save: "Enregistrer",
        verify: "Vérifier",
        found_contacts: "Nombre d'électeurs'",
        errored_lines: "Lignes erronées",
        no_contact: "Il faut au moins un électeur"
      },
      rawcontacts: {
        textlines: "Liste des électeurs",
        textlines_sub: "1 ligne / électeur: une ou plusieurs adresses email, et éventuellement le nom de l'électeur",
        textlines_ph: "John Doe john@doe.com johndoe@gmail.com\njack@outlook.com\nJean <jean@protonmail.com>",
        help_title_how: "Comment entrer la liste des électeurs",
        help_how_1: "Vous pouvez ici composer ou coller la liste des électeurs: un par ligne.",
        help_how_2: "Chaque électeur doit avoir au moins une adresse email; vous pouvez lui associer plusieurs adresses mail: il aura toujours droit à un seul vote.",
        help_how_3: "Les mots autres sont interprêtés comme le nom de l'électeur, et les caractères spéciaux sont ignorés.",
        help_how_4: "Par exemple: 'Monsieur john@doe.com John Doe' est interprêté comme:",
        help_how_5: "Adresses email: 'john@doe.com'",
        help_how_6: "Nom: 'Monsieur John Doe'",
        help_how_7: "La ligne: 'Monsieur John Doe <john@doe.com>' donnera le même résultat.",
        help_title_use: "Usage des adresses email",
        help_use_1: "Les adresses emails sont utilisées EXCLUSIVEMENT pour valider la participation à ce scrutin.",
        help_use_2: "Nous n'enverrons aucun mail promotionnel ou autre à ces participants.",
        help_use_3: "Nous ne partageons pas ces adresses.",
        help_use_4: "Même dans le cadre de ce scrutin, aucun email ne sera envoyé sans autorisation, le processus est le suivant",
        help_use_5: "Vous communiquez aux électeurs un lien pour voter (le même pour tous).",
        help_use_6: "L'électeur entre son email, et le système vérifie qu'il est enregistré'.",
        help_use_7: "Seulement à ce moment, un email lui est envoyé pour communiquer le code confidentiel pour voter.",
        save: "Enregistrer",
        found_contacts: "Nombre d'électeurs'",
        errored_lines: "Lignes erronées",
        no_contact: "Il faut au moins un électeur"
      },
      candidates: {
        textlines: "Liste des candidats ",
        textlines_sub: "1 ligne / candidat: sera affiché tel quel aux participants",
        textlines_ph: "Jean\nJack",
        help_title_how: "Comment entrer la liste des candidats",
        help_how_1: "Vous pouvez ici composer ou coller la liste des candidats: un par ligne.",
        help_how_2: "Chaque électeur pourra voter une seule fois, il a le droit de sélectionner un ou plusieurs candidats",
        help_how_3: "(selon votre choix dans la case ci-dessus)",
        num_votes: "Nombre de candidats par électeur",
        num_votes_sub: "L'électeur pourra choisir un ou plusieurs candidats",
        per_voter: "seul candidat sélectionnable pour chaque électeur",
        per_voter_plural: "candidats sélectionnables pour chaque électeur",
        err_missingvalue: "valeur numérique attendue",
        err_maxvotes: "ne peut être supérieur au nombre de candidats",

        save: "Enregistrer",
        found_contacts: "Nombre de candidats distincts",
        errored_lines: "Lignes erronées",
        no_contact: "Il faut au moins un candidat",
        bad_nvotes: "Valeur numérique attendue"
      },
      summary: {
        head: "Résumé",
        head_sub: "du scrutin",
        test_mode: "Le scrutin est prêt à être testé.",
        has_errors: "Les informations du scrutin sont incomplètes: compléter et enregistrer tous les onglets.",
        scrutin_dn: "Scrutin",
        orga_dn: "Organisé par",
        orga_mail: "Email de l'organisateur (non communiqué aux électeurs)",
        fromdate: "Ouvert de",
        todate: "à",
        vote_nbvotes: "Chaque électeur peut voter une seule fois, en sélectionnant au plus 1 candidat.",
        vote_nbvotes_plural: "Chaque électeur peut voter une seule fois, en sélectionnant au plus {{count}} candidats.",
        candidates: "Liste des candidats",
        voters: "Liste des électeurs",
        gotest: "Tester",
        goresults: "Résultats",
        vote_info_title: "Déroulement du scrutin",
        vote_link: "Un lien pour accéder au scrutin va vous être communiqué: vous l'envoyez vous-même à tous les électeurs.",
        vote_info1: "Ce lien ouvre une page permettant à un électeur d'entrer son adresse email (un électeur avec plusieurs adresses email ne vote qu'une fois).",
        vote_info2: "Si il est bien inscrit comme électeur, un email lui est envoyé, avec son code confidentiel de vote et un lien de vote.",
        vote_info3: "En cliquant sur ce lien, l'électeur voit les {{ncands}} candidats et peut en sélectionner au plus {{nvotes}}.",
        vote_info4: "Le vote est définitif: une fois validé, il ne peut plus être modifié.",
        vote_info5: "Les résultats ne sont pas accessibles pendant la durée d'ouverture du scrutin. Ils indiqueront le nombre de voix par candidat et le taux de participation."
      },
      adminview: {
        back_on_error: "Retour"
      },
      testview: {
        test_1: "Ce scrutin est actuellement en mode test. Il n'est pas ouvert au vote.",
        test_2: "Dans ce mode:",
        test_3: "Vous pouvez voter à la place de tout électeur: tous les emails vous sont envoyés, et le code confidentiel de vote est 1234.",
        test_4: "Vous pouvez voir l'évolution des résultats en temps réel.",
        test_5: "Vous pouvez modifier le scrutin.",
        test_update: "Modifier le scrutin",
        vote_link: "Le lien pour accéder au scrutin (que vous devrez communiquer vous-même à tous les électeurs) est le suivant: ",
        vote_info1: "Ce lien ouvre une page permettant à un électeur d'entrer son adresse email, pour recevoir son code confidentiel et les instructions pour voter.",
        test_results: "Résultats",
        test_refresh: "mettre à jour",
        candidate: "Candidat",
        nbvotes: "Nombre de voix",
        maintenant: "maintenant",
        validate_info1: "Cliquez sur le bouton 'Valider' pour valider le scrutin et quitter le mode test.",
        validate_info2: "Vous pourrez revenir en mode test ou modifier le scrutin avant le {{- start}}.",
        validate_info2_irreversible: "Cette action est irréversible: le scrutin sera ouvert dès maintenant.",
        validate_info3: "",
        validate_info4: "Les votes seront acceptés du {{- min}} au {{- max}} inclus. Les résultats seront ensuite accessibles à vous et à tous les électeurs.",
        validate_info4_irreversible: "Les votes seront acceptés jusqu'au {{- max}} inclus. Les résultats seront ensuite accessibles à vous et à tous les électeurs.",
        validate_info5: "En cours de scrutin, la seule information disponible est le taux de participation.",
        validate: "Valider le scrutin",
        validate_err_enddate: "Le scrutin ne peut pas être validé: date de fin trop proche."
      },
      readyview: {
        ready_1: "Ce scrutin est validé.",
        future_start: "Il sera ouvert au vote du {{-min}} au {{-max}}",
        imminent_start: "Il va être ouvert au vote dans les prochaines minutes, et fermera le {{-max}}",
        access_link: "Communiquez vous-même à tous les électeurs le lien ci-dessous pour accéder au scrutin: ",
        access_info1: "Ce lien ouvre une page permettant à un électeur d'entrer son adresse email, pour recevoir son code confidentiel et les instructions pour voter.",
        back_to_test: "Jusqu'à 5 minutes avant l'ouverture, il est possible de ",
        back_to_test_link: "revenir à l'étape précédente",
        view_scrutin: "Détails du scrutin",
        vote_link: "Envoyez vous-même le lien d'accès à tous les électeurs.",
        vote_info3: "En cliquant sur ce lien, l'électeur est authentifié et peut choisir son ou ses candidats.",
      },
      activeview: {
        active_1: "Ce scrutin est en cours.",
        end_date: "Il fermera le {{-max}}",
        access_link: "Les électeurs doivent utiliser le lien ci-dessous pour accéder au scrutin: ",
        access_info1: "Ce lien ouvre une page permettant à un électeur d'entrer son adresse email, pour recevoir son code confidentiel et les instructions pour voter.",
        no_modif: "Pour respecter l'intégrité du vote, aucune modification au scrutin n'est permise",
        view_scrutin: "Détails du scrutin",
        vote_link: "Le lien d'accès doit avoir été transmis à tous les électeurs.",
        vote_info3: "En cliquant sur ce lien, l'électeur est authentifié et peut choisir son ou ses candidats.",
      },
      results: {
        head: "Résultat",
        head_sub: "du scrutin",
        err_inprogress: "Le scrutin est en cours. Seuls le taux de participation et la liste des électeurs qui ont exprimé leur vote sont visibles.",
        scrutin_dn: "Scrutin: ",
        orga_dn: "Organisé par",
        orga_mail: "Email de l'organisateur (non communiqué aux électeurs)",
        fromdate: "Ouvert du",
        todate: "au",
        included: "inclus",
        vote_nbvotes: "Chaque électeur peut voter une seule fois, en sélectionnant au plus 1 candidat.",
        vote_nbvotes_plural: "Chaque électeur peut voter une seule fois, en sélectionnant au plus {{count}} candidats.",
        results: "Résultats",
        participation: "Taux de participation",
        nbVotes: "Nombre d'électeurs ayant exprimé leur voix",
        nbVoters: "Nombre total d'électeurs",
        candidate: "Candidat",
        nbvotes: "Nombre de voix",
        view_scrutin: "Détails du scrutin",
        delete: "Supprimer le scrutin",
        delete_cond: "Un scrutin peut être supprimé soit avant sa date d'ouverture, soit un jour après sa date de clôture.",
        info_title: "Merci !",
        info1: "Merci d'avoir utilisé ce service. Si vous en avez été satisfait, parlez-en autour de vous :)"
      },
      scrutinstatus: {
        local_edit: "Le scrutin est en cours de création.",
        in_test: "Le scrutin est en cours de test.",
        validated: "Le scrutin est validé et sera ouvert aux votes le {{- min}}",
        open: "Le scrutin est actuellement ouvert et les électeurs peuvent voter. Il fermera le {{-max}}.",
        closed: "Le scrutin est terminé.",
        cancelled: "Le scrutin a été annulé.",
        unknown: "Etat inconnu (une erreur est survenue)."
      },
      navbar: {
        scrutin: 'scrutin',
        voters: 'électeurs',
        candidates: 'candidats',
        summary: 'résumé',
        test: 'test',
        results: 'résultats'
      },
      editor: {
        crea_title: "Création d'un nouveau scrutin",
        edit_title: "Edition du scrutin",
        tabname_0: "description",
        tabname_1: "électeurs",
        tabname_2: "candidats",
        tabname_3: "résumé",
        cancel_title: "Informations non sauvegardées",
        cancel_create: "Abandonner la création du scrutin ?",
        cancel_edit: "Abandonner les modifications ?",
        cancel_btn_yes: "Abandonner",
        cancel_btn_no: "Ne pas abandonner"
      },
      votepage: {
        openfromto: "Période d'ouverture",
        opento: "Ouvert jusqu'au",
        initialloading: "Chargement en cours...",
        scrutin_not_found: "Vérifiez l'adresse de la page",
        comm_error: "Reessayez ultérieurement",
        emailfield: "Entrez votre email",
        validate_mail: "Valider",
        unknown_voter_mail: "Cet email ne correspond à aucun électeur.",
        oldmessagesent: "Un email avec votre code confidentiel vous avait été envoyé.",
        oldmessagestatus: "si vous ne n'avez pas reçu ou ne le retrouvez pas, demandez son renvoi",
        recentmessagesent: "Envoi d'un email avec votre code confidentiel...",
        mail_valid: "email validé",
        codefield: "Entrez votre code confidentiel",
        sendbtn: {
          SEND: "m'envoyer le code par email",
          RESEND: "renvoyer le code par email",
          INPROGRESS: "récemment envoyé",
        },
        sending_code: "envoi du code",
        sendsecret_tooltip: "cet email doit correspondre à un électeur",
        candidates: "Choisissez un candidat",
        candidates_plural: "Choisissez au plus {{count}} candidats",
        blank: "Vote blanc",
        maxcandidates: "Trop de candidats sélectionnés: maximum {{count}}"
      }
    }
  }
};
import { EventEmitter } from "events";

export class AuthState {
  public username: string = "";
  public password: string = "";
  /** Last op. succeeded or failed for a non-auth related error. */
  public authOk: boolean = false;
  /** Last op. failed with an Unauthenticated error. */
  public authFailed: boolean = false;
}

export class AuthStore extends EventEmitter {
  private authState = new AuthState();

  public static CHANGEEVENT = "AuthState";

  public getAuthState = () => (this.authState);

  public disconnected = () => {
    this.authState.username = "";
    this.authState.password = "";
    this.authState.authOk = false;
    this.authState.authFailed = false;
    this.emit(AuthStore.CHANGEEVENT);
  }

  public connected = (username: string, password: string) => {
    this.authState.username = username;
    this.authState.password = password;
    this.authState.authOk = true;
    this.authState.authFailed = false;
    this.emit(AuthStore.CHANGEEVENT);
  }

  public failed = (username: string, password: string) => {
    this.authState.username = username;
    this.authState.password = password;
    this.authState.authOk = false;
    this.authState.authFailed = true;
    this.emit(AuthStore.CHANGEEVENT);
  }
}

/** Current instance, may be replaced for Testing. */
let currentInstance: AuthStore = new AuthStore();

export const getAuthStore = () => (currentInstance);
export const setAuthStore = (authStore: AuthStore) => {
  currentInstance = authStore;
}

/**
 * Definitions for objects exchanged with Admin Backend.
 */

import { SStatus } from "../../common/tsmodel/TsModelDefs";
import { FromTsBody } from "../../common/tsmodel/TsQueries";
import { TsResponse } from "../../common/tsmodel/TsResponses";
import { TsAdmScrutinResponse } from "./TsAdmScrutinResponse";

export class TsAdmQuery extends FromTsBody {
  /** Admin User. */
  username: string;
  /** Authentication Information (password, for now). */
  auth: string;

  constructor(username: string, auth: string) {
    super();
    this.username = username;
    this.auth = auth;
  }
}

export class TsAdmListReq extends TsAdmQuery {
  /** List Scrutins in this Status. */
  sStatus: SStatus;

  /** Page Number */
  pageNumber: number;

  /** Page Size */
  pageSize: number;

  constructor(username: string, auth: string, sStatus: SStatus, pageNumber: number, pageSize: number) {
    super(username, auth);
    this.sStatus = sStatus;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export class TsAdmMacSid extends TsAdmQuery {
  mac: string;
  scrutinId: string;

  constructor(username: string, auth: string, mac: string, scrutinId: string) {
    super(username, auth);
    this.mac = mac;
    this.scrutinId = scrutinId;
  }
}
export interface TsAdmScrutinsPageReponse extends TsResponse {
  data: TsAdmScrutinResponse[]
}

export interface TsDeleteReportMsg extends TsResponse, TsDeleteReport { }

export interface TsDeleteReport {
  mac: string;
  scrutinId: string;
  testVotesRemoved: boolean;
  realVotesRemoved: boolean;
  scrutinRemoved: boolean;
  sidRemoved: boolean;
  macRemoved: boolean;
}

export interface TsAdmDeleteListReponse extends TsResponse {
  data: TsDeleteReport[];
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18next from 'i18next';
import { XLations } from './common/XLations';
import { PmConsoleLog } from './common/util/pmconsolelog';
import { setLogger, LogLevel } from './common/util/pmlogger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const logr: PmConsoleLog = new PmConsoleLog();
logr.setDefaultLevel(LogLevel.DEBUG);
logr.addSpecificLevel("rsubtabs", LogLevel.DEBUG);
setLogger(logr);

i18next.init({ resources: XLations, lng: 'fr', fallbackLng: 'fr', debug: false }).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});



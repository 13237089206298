import { getHttpLayer } from "../common/backend/HttpLayer";
import { TsAdmDeleteListReponse, TsAdmListReq, TsAdmMacSid, TsAdmQuery, TsAdmScrutinsPageReponse, TsDeleteReportMsg } from "./tsadmmodel/TsAdmMessages";
import { SStatus } from "../common/tsmodel/TsModelDefs";
import { TsPingBody } from "../common/tsmodel/TsQueries";
import { TsPingResponse, TsResponse } from "../common/tsmodel/TsResponses";

export interface AdmApi {
  /** Allows to check that some data volume passes back and forth. */
  admPingPost: (data: string) => Promise<TsPingResponse>;

  /** Returns a Page of Scrutins in the specified Status. */
  admLists: (username: string, auth: string, sStatus: SStatus, pageNumber: number, pageSize: number) => Promise<TsAdmScrutinsPageReponse>;

  /** Deletes all data of a scrutin. */
  admDelScrutin: (username: string, auth: string, mac: string, scrutinId: string) => Promise<TsDeleteReportMsg>;

  /** Check admin credentials. */
  admCheck: (username: string, auth: string) => Promise<TsResponse>;

  /** Delete Test Scrutins. */
  admDelTestScrutins: (username: string, auth: string) => Promise<TsAdmDeleteListReponse>;
}

/** Implementation */
export class AdmApiImpl implements AdmApi {
  public admPingPost = (data: string): Promise<TsPingResponse> => {
    const body = new TsPingBody(data);
    return getHttpLayer().HttpPostPromise<TsPingBody, TsPingResponse>("/admpingpost", body);
  }

  public admLists = (username: string, auth: string, sStatus: SStatus, pageNumber: number, pageSize: number): Promise<TsAdmScrutinsPageReponse> => {
    const body = new TsAdmListReq(username, auth, sStatus, pageNumber, pageSize);
    return getHttpLayer().HttpPostPromise<TsAdmListReq, TsAdmScrutinsPageReponse>("/admlists", body);
  }

  public admDelScrutin = (username: string, auth: string, mac: string, scrutinId: string): Promise<TsDeleteReportMsg> => {
    const body = new TsAdmMacSid(username, auth, mac, scrutinId);
    return getHttpLayer().HttpPostPromise<TsAdmMacSid, TsDeleteReportMsg>("/admdelscrutin", body);
  }

  public admCheck = (username: string, auth: string): Promise<TsResponse> => {
    const body = new TsAdmQuery(username, auth);
    return getHttpLayer().HttpPostPromise<TsAdmQuery, TsResponse>("/admcheck", body);
  }

  public admDelTestScrutins = (username: string, auth: string): Promise<TsAdmDeleteListReponse> => {
    const body = new TsAdmQuery(username, auth);
    return getHttpLayer().HttpPostPromise<TsAdmQuery, TsAdmDeleteListReponse>("/admdeltestscrutins", body);
  }
}

/** Current instance, may be replaced for Testing. */
let currentInstance: AdmApi = new AdmApiImpl();

export const getAdmApi = () => (currentInstance);
export const setAdmApi = (admApi: AdmApi) => {
  currentInstance = admApi;
}

import * as React from 'react';
import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap';
import { AuthStore, getAuthStore } from '../auth/AuthStore';
import { getAdmApi } from '../backend/AdmApi';
import { TsAdmScrutinsPageReponse, TsDeleteReport, TsDeleteReportMsg } from '../backend/tsadmmodel/TsAdmMessages';
import { TsAdmScrutinResponse } from '../backend/tsadmmodel/TsAdmScrutinResponse';
import { errMessage } from '../common/tsmodel/Converters';
import { getLogger } from '../common/util/pmlogger';
import { ScrutinFilter, ScrutinRowFilter } from './ScrutinFilter';
import { ScrutinRow, ScrutinTableHeader } from './ScrutinRow';

interface ScState {
  filter: ScrutinRowFilter,
  selectedMacs: string[],
  loading: boolean;
  pageData?: TsAdmScrutinsPageReponse;
  pageNumber: number;
  pageSize: number;
  delReports: string[],
  delConfirm: boolean,
  errMessage?: string
}

export class Scrutins extends React.Component<{}, ScState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      filter: { status: 'ACTIVE', pageNumber: 0 },
      selectedMacs: [],
      loading: false,
      pageNumber: 1,
      pageSize: 60,
      delReports: [],
      delConfirm: false
    };
  }

  public componentDidMount = () => {
    getAuthStore().on(AuthStore.CHANGEEVENT, this.doUpdate);
  }

  public componentWillUnmount = () => {
    getAuthStore().off(AuthStore.CHANGEEVENT, this.doUpdate);
  }

  private doUpdate = () => {
    this.forceUpdate();
  }

  private loadFromServer = (filter: ScrutinRowFilter) => {
    const username = getAuthStore().getAuthState().username;
    const password = getAuthStore().getAuthState().password;
    getAdmApi().admLists(username, password, filter.status, filter.pageNumber, this.state.pageSize).then(pageResponse => {
      console.log("PageResponse: %o", pageResponse);
      this.setState({ loading: false, pageData: pageResponse, filter });
    })
  }

  private onSelect = (mac: string) => {
    if (!this.state.selectedMacs.includes(mac)) {
      const updatedMacs = this.state.selectedMacs.map(amac => (amac));
      updatedMacs.push(mac);
      this.setState({ selectedMacs: updatedMacs });
    } else {
      const updatedMacs = this.state.selectedMacs.filter(amac => (amac !== mac));
      this.setState({ selectedMacs: updatedMacs });
    }
  }

  private onFilterChange = (filter: ScrutinRowFilter) => {
    this.setState({ filter });
  }

  private onFilterUpdate = (newFilter: ScrutinRowFilter) => {
    this.setState({ loading: true });
    setTimeout(this.loadFromServer.bind(this, newFilter), 1000);
  }

  private onDeleteSelected = () => {
    this.setState({ delConfirm: true });
  }
  private onConfirmDelete = () => {
    this.setState({ delConfirm: false, delReports: [] });
    this.onDeleteNextSelected();
  }
  private onCancelDelete = () => {
    this.setState({ delConfirm: false });
  }
  private onDeleteNextSelected = () => {
    if (this.state.selectedMacs.length === 0) {
      const delReports = this.state.delReports;
      delReports.push("-- End of Delete Reports");
      this.setState({ delReports });
      return;
    }
    const mac = this.state.selectedMacs.pop();
    const scrut = this.state.pageData?.data.find(scr => (scr.mac === mac));
    if (!scrut) {
      const delReports = this.state.delReports;
      delReports.push("-- Scrutin not found for mac " + mac);
      this.setState({ delReports });
    } else {
      const username = getAuthStore().getAuthState().username;
      const password = getAuthStore().getAuthState().password;
      getAdmApi().admDelScrutin(username, password, scrut.mac, scrut.scrutinId).then(tsDelReport => {
        const reportMsg = reportMessage(tsDelReport);
        const feedback = reportMsg + " for: " + scrut.sDisplayName + " (" + scrut.scrutinId + ")";
        const delReports = this.state.delReports;
        delReports.push(feedback);
        this.setState({ delReports });
        this.onDeleteNextSelected();
      }).catch(errCode => {
        getLogger().info("Delete Failed: %s", errMessage(errCode));
        const delReports = this.state.delReports;
        delReports.push("Delete Failed: " + errMessage(errCode));
        this.setState({ delReports });
      });
    }
  }

  private onDismiss = () => {
    this.setState({ delReports: [] });
    this.onFilterUpdate(this.state.filter);
  }

  private onDeleteTestScrutins = () => {
    const username = getAuthStore().getAuthState().username;
    const password = getAuthStore().getAuthState().password;
    getAdmApi().admDelTestScrutins(username, password).then(tsDelListReport => {
      const delReports = this.state.delReports;
      tsDelListReport.data.forEach(tsDelReport => {
        const reportMsg = reportMesg(tsDelReport);
        const feedback = reportMsg + " for: " + tsDelReport.mac + " / " + tsDelReport.scrutinId + ")";
        delReports.push(feedback);
      });
      this.setState({ delReports });
    }).catch(errCode => {
      getLogger().info("Delete Test Scrutins Failed: %s", errMessage(errCode));
      const delReports = this.state.delReports;
      delReports.push("Delete Test Scrutins Failed: " + errMessage(errCode));
      this.setState({ delReports });
    });
  }

  public render() {
    const authOk = getAuthStore().getAuthState().authOk;
    const res = this.state.pageData;
    const errorMsg = (res && res.ret !== 0 ? res.msg : undefined);
    const rows: TsAdmScrutinResponse[] = res && res.ret === 0 ? res.data : [];

    return (
      <Container>
        {authOk ?
          <ScrutinFilter onUpdate={this.onFilterUpdate} onChange={this.onFilterChange} />
          : null}
        {errorMsg ?
          <Row className='ScrutinsErr'>Error: {errorMsg}</Row> : null
        }
        {this.state.delConfirm ?
          <Row className='RowMargins'>
            <Col>Confirm delete {this.state.selectedMacs.length} scrutins ?</Col>
            <Col><Button variant='danger' onClick={this.onConfirmDelete}>delete</Button></Col>
            <Col><Button variant='secondary' onClick={this.onCancelDelete}>cancel</Button></Col>
          </Row>
          : null}
        {this.state.delReports.length !== 0 ?
          <Row><Col>
            <Alert variant='success' onClose={this.onDismiss} dismissible><Alert.Heading>Delete Reports</Alert.Heading>
              {this.state.delReports.map(delReport => (<Row><Col>{delReport}</Col></Row>))}
            </Alert>
          </Col></Row> : null
        }
        <Table>
          <ScrutinTableHeader />
          <tbody>
            {rows.map(scrutinResp =>
            (<ScrutinRow key={scrutinResp.mac} scrutin={scrutinResp} filter={this.state.filter}
              selected={this.state.selectedMacs.includes(scrutinResp.mac)} onSelect={this.onSelect} />)
            )}
          </tbody>
        </Table>
        <Row>
          <Col>
            <Button variant='primary' onClick={this.onDeleteTestScrutins}>Delete Test scrutins</Button>
          </Col>
          <Col>{this.state.selectedMacs.length !== 0 ?
            <Button variant='danger' onClick={this.onDeleteSelected}>Delete selected scrutins</Button>
            : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

function reportMessage(delReport: TsDeleteReportMsg) {
  if (delReport.ret !== 0) {
    return "DeleteError: " + delReport.ret + " " + delReport.msg;
  }
  return reportMesg(delReport);
}
function reportMesg(delReport: TsDeleteReport) {
  let msg = "Deleted: ";
  if (delReport.scrutinRemoved) {
    msg += "Scrutin, ";
  }
  if (delReport.macRemoved) {
    msg += "Mac, ";
  }
  if (delReport.sidRemoved) {
    msg += "ScrutinID, ";
  }
  if (delReport.testVotesRemoved) {
    msg += "Test Votes, ";
  }
  if (delReport.realVotesRemoved) {
    msg += "** Real Votes ** ";
  }
  return msg;
}
import React from 'react';
import '../App.css';
import { SStatus } from '../common/tsmodel/TsModelDefs';
import { DatesManager } from '../common/util/DatesManager';
import { DateTime } from 'luxon';
import { TsAdmScrutinResponse } from '../backend/tsadmmodel/TsAdmScrutinResponse';
import { ScrutinRowFilter } from './ScrutinFilter';

export interface SRProps {
  scrutin: TsAdmScrutinResponse,
  filter: ScrutinRowFilter,
  selected: boolean,
  onSelect: (mac: string) => void
}

export class ScrutinRow extends React.Component<SRProps> {

  constructor(props: SRProps) {
    super(props);
  }

  private match = (): boolean => {
    const sc = this.props.scrutin;
    const filter = this.props.filter;
    if (filter.mac && (!sc.mac.includes(filter.mac))) {
      return false;
    }
    if (filter.scrutinId && (!sc.scrutinId.includes(filter.scrutinId))) {
      return false;
    }
    if (filter.orga && (!sc.sOrganizerName.includes(filter.orga)) && (!sc.sOrganizerEmail.includes(filter.orga))) {
      return false;
    }
    if (filter.title && (!sc.sDisplayName.includes(filter.title))) {
      return false;
    }
    if (filter.status && sc.sStatus !== filter.status) {
      return false;
    }
    if (filter.openAfter && sc.openDateMillis < filter.openAfter) {
      return false;
    }
    if (filter.openBefore && sc.openDateMillis > filter.openBefore) {
      return false;
    }
    return true;
  }

  private onSelect = () => {
    this.props.onSelect(this.props.scrutin.mac);
  }

  public render() {
    if (!this.match()) {
      return null;
    }
    const sc = this.props.scrutin;
    const startMoment = DateTime.fromMillis(sc.openDateMillis);
    const endMoment = DateTime.fromMillis(sc.closeDateMillis);
    const startDateStr = DatesManager.get().formatVeryShortNumericDateTime(startMoment);
    const endDateStr = DatesManager.get().formatVeryShortYearlessNumericDateTime(endMoment);
    const scVoteUrl = sc.tsVoteUrl + sc.scrutinId + (sc.sStatus === 'ACTIVE' ? "" : "/" + sc.mac);
    // selon date past ou future griser la date corresp
    return <tr className='ScrutinsRow'>
      <td>{sc.sOrganizerName} ({sc.sOrganizerEmail})</td>
      <td>{sc.sDisplayName}</td>
      {/*<StatusCol sStatus={sc.sStatus} /> */}
      <td>{startDateStr}-{endDateStr}</td>
      <td><a href={sc.tsFrontUrl + "admin/" + sc.mac + "/" + sc.scrutinId} target='_blank' rel='noreferrer'>admin</a></td>
      <td><a href={scVoteUrl} target='_blank' rel='noreferrer'>vote</a></td>
      <td><input type='checkbox' checked={this.props.selected} onClick={this.onSelect} /></td>
    </tr>
  }
}

export function ScrutinTableHeader(props: {}) {
  return <thead>
    <tr>
      <th>Organisateur</th>
      <th>Titre</th>
      <th>Open-Close</th>
      <th>admin</th>
      <th>vote</th>
      <th>del</th>
    </tr>
  </thead>
}

// eslint-disable-next-line
function StatusCol(props: { sStatus: SStatus }) {
  if (props.sStatus === 'ACTIVE') {
    return <td className="AdminStatusActive">ACTIVE</td>;
  } else if (props.sStatus === 'FINISHED') {
    return <td className="AdminStatusFinished">Finished</td>;
  } else {
    return <td className="AdminStatusFuture">{props.sStatus}</td>;
  }
}
import React from 'react';
import './App.css';
import { setHttpLayer } from './common/backend/HttpLayer';
import { HttpLayerImpl } from './common/backend/HttpLayerImpl';
import { AuthDialog } from './uicomponents/AuthDialog';
import { Scrutins } from './uicomponents/Scrutins';

const API_URL = "https://vootation.com/api";
//const API_URL = "http://test.vootation.com/api";

function App() {
  setHttpLayer(new HttpLayerImpl(API_URL));
  return (
    <div className="App">
      <AuthDialog />
      <Scrutins />
    </div>
  );
}

export default App;

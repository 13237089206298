import ReactDatePicker from 'react-datepicker';
import React from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import { SStatus } from '../common/tsmodel/TsModelDefs';

export interface SRProps {
  /** invoked each time something changes. */
  onChange?: (filter: ScrutinRowFilter) => void;
  /** invoked when the Update Button is clicked. */
  onUpdate?: (filter: ScrutinRowFilter) => void;
}
export interface ScrutinRowFilter {
  mac?: string,
  scrutinId?: string,
  orga?: string,
  title?: string,
  openAfter?: number,   // Date in millis
  openBefore?: number,   // Date in millis
  status: SStatus,    // Mandatory
  pageNumber: number  // Mandatory
}

export class ScrutinFilter extends React.Component<SRProps, ScrutinRowFilter> {

  private currentFilter: ScrutinRowFilter = { status: 'ACTIVE', pageNumber: 1 };

  constructor(props: SRProps) {
    super(props);
    this.state = { status: 'ACTIVE', pageNumber: 1 };
  }

  private onChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.currentFilter);
    }
  }

  private onTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.currentFilter.title = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
    this.setState({ title: this.currentFilter.title });
    this.onChange();
  }
  private onOrga = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.currentFilter.orga = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
    this.setState({ orga: this.currentFilter.orga });
    this.onChange();
  }
  private onMac = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.currentFilter.mac = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
    this.setState({ mac: this.currentFilter.mac });
    this.onChange();
  }
  private onScrutinId = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.currentFilter.scrutinId = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
    this.setState({ scrutinId: this.currentFilter.scrutinId });
    this.onChange();
  }
  private onSStatus = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const v = e.currentTarget.value;
    this.currentFilter.status = v as SStatus;
    this.setState({ status: this.currentFilter.status });
    this.onChange();
  }
  private onOpenAfter = (openAfterDate: Date) => {
    if (openAfterDate.getTime() > new Date().getTime()) {
      // Setting an Open After later than now resets the openAfter criterium.
      this.currentFilter.openAfter = undefined;
      this.setState({ openAfter: undefined });
    } else {
      this.currentFilter.openAfter = openAfterDate.getTime();
      this.setState({ openAfter: openAfterDate.getTime() });
    }
    this.onChange();
  }
  private onOpenBefore = (openBeforeDate: Date) => {
    if (openBeforeDate.getTime() > new Date().getTime()) {
      // Setting an Open Before later than now resets the openBefore criterium.
      this.currentFilter.openBefore = undefined;
      this.setState({ openBefore: undefined });
    } else {
      this.currentFilter.openBefore = openBeforeDate.getTime();
      this.setState({ openBefore: openBeforeDate.getTime() });
    }
    this.onChange();
  }
  private onPageMinus = () => {
    if (this.state.pageNumber > 1) {
      this.currentFilter.pageNumber = this.state.pageNumber - 1;
      this.setState({ pageNumber: this.state.pageNumber - 1 });
      this.onChange();
    }
  }
  private onPagePlus = () => {
    this.currentFilter.pageNumber = this.state.pageNumber + 1;
    this.setState({ pageNumber: this.state.pageNumber + 1 });
    this.onChange();
  }
  private onPageNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const v = e.currentTarget.value;
    const pg = parseInt(v);
    if (!isNaN(pg)) {
      this.currentFilter.pageNumber = pg;
      this.setState({ pageNumber: pg });
      this.onChange();
    }
  }
  private onUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate(this.currentFilter);
    }
  }

  public render() {
    const st = this.state;
    const opAfterDate = st.openAfter ? new Date(st.openAfter) : undefined;
    const opBeforeDate = st.openBefore ? new Date(st.openBefore) : undefined;
    return <Form className='FilterRow'>
      <Row>
        <Col>
          <Form.Group controlId="formSDname">
            <Form.Label>Scrutin Displayname</Form.Label>
            <Form.Control type='text' placeholder="scrutin d.Name" value={st.title || ""} onChange={this.onTitle} />
          </Form.Group>
        </Col><Col>
          <Form.Group controlId="formSOrga">
            <Form.Label>Organizer</Form.Label>
            <Form.Control type='text' placeholder="organizer" value={st.orga || ""} onChange={this.onOrga} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formMac">
            <Form.Label>MAC Value</Form.Label>
            <Form.Control type='text' placeholder="mac-value" value={st.mac || ""} onChange={this.onMac} />
          </Form.Group>
        </Col><Col>
          <Form.Group controlId="formSid">
            <Form.Label>ScrutinId</Form.Label>
            <Form.Control type='text' placeholder="scrutin-id" value={st.scrutinId || ""} onChange={this.onScrutinId} />
          </Form.Group>
        </Col>        <Col>
          <Form.Group controlId="formOpenAfter">
            <Form.Label>Open after: </Form.Label><div>
              <ReactDatePicker onChange={this.onOpenAfter} selected={opAfterDate} dateFormat='dd/MM/yyyy' />
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formOpenBefore">
            <Form.Label>Open before: </Form.Label><div>
              <ReactDatePicker onChange={this.onOpenBefore} selected={opBeforeDate} dateFormat='dd/MM/yyyy' />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control as="select" value={st.status.toString()} onChange={this.onSStatus}>
              <option>TESTING</option>
              <option>READY</option>
              <option>ACTIVE</option>
              <option>FINISHED</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="formStatus">
            <Form.Label>Page</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <Button variant="info" onClick={this.onPageMinus}>&lt;</Button>
              </InputGroup.Prepend>
              <Form.Control type='text' value={st.pageNumber} onChange={this.onPageNumber} style={{ textAlign: 'center' }} />
              <InputGroup.Append>
                <Button variant="info" onClick={this.onPagePlus}>&gt;</Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
        {this.props.onUpdate ?
          <Col md={2} className='FilterUpdateCol'>
            <Button variant='primary' className='FilterUpdateBtn' onClick={this.onUpdate}>update</Button>
          </Col>
          : null}
      </Row>
    </Form >
  }
}